import React from "react";
import "./style.css";
import CarouselButton from "../../components/Button/CarouselButton";
import { MdEmail, MdPhoneIphone } from "react-icons/md";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import Image from "../../assets/talks-colleagues-working-together-modern-office-using-devices-gadgets-creative-meeting (2).jpg";

import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    toast.success(
      "Many thanks for your interest! We'll give this a read and get back to you soon"
    );
    reset();
    const response = await fetch("https://nexonetics.com/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.status !== 200) {
      toast.error("Failed to send email");
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-image">
        <img src={Image} alt="Office" />
      </div>
      <div className="contact-div">
        <div className="carousel-button-div">
          <CarouselButton />
        </div>
        <div className="form-div">
          <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-text-div">
              <div className="Labelcontent">
                Name<span className="required-star">*</span>
              </div>
              <input
                className="inputContent"
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span className="error-message">This field is required</span>
              )}
            </div>

            <div className="form-text-div">
              <div className="Labelcontent">
                Email <span className="required-star">*</span>
              </div>
              <input
                className="inputContent"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className="error-message">This field is required</span>
              )}
            </div>

            <div className="form-text-div">
              <div className="Labelcontent">
                Phone Number <span className="required-star">*</span>
              </div>
              <input
                className="inputContent"
                {...register("phoneNumber", { required: true })}
              />
              {errors.phoneNumber && (
                <span className="error-message">This field is required</span>
              )}
            </div>

            <div className="form-text-div descriptionDiv">
              <div className="DescriptionContainer">Guide us to your Dream</div>
              <textarea {...register("description")} />
            </div>
            <div className="submitButton">
              <button type="submit" className="btn-submit">
                Submit
              </button>
            </div>
          </form>
        </div>

        <div className="contact-address">
          <div className="office-address">
            <h4 className="office-head">Office</h4>
            <div>69/3179, Faby homes,</div>
            <div>Dr palpu lane,</div>
            <div>SRM road, Kochi, 18</div>
          </div>
          <div className="office-address">
            <h4 className="office-head">Talk to us</h4>
            <div>
              <MdEmail /> info@Behelam.com
            </div>
            <div>
              <MdPhoneIphone /> +91 96455 09057
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Contact;
