import React from "react";
import { useNavigate } from "react-router-dom";
import CarouselButton from "../../components/Button/CarouselButton";
import { jobListings } from "./data";
import "./style.css";

const JobCard = ({ job, onClick }) => {
  return (
    <div className="job-card" onClick={onClick}>
      <div className="job-card-content">
        <h3 className="job-card-title">{job.title}</h3>
        <div className="job-card-details">
          <span className="job-detail location">{job.location}</span>
          <span className="job-detail department">{job.department}</span>
          <span className="job-detail experience">{job.experience}</span>
        </div>
        <p className="job-card-description">{job.description}</p>
        <button className="view-details-btn">View Details</button>
      </div>
    </div>
  );
};

const CareerPage = () => {
  const navigate = useNavigate();

  const handleJobClick = (jobId) => {
    navigate(`/career/${jobId}`);
  };

  return (
    <div className="career-container">
      <div className="career-hero">
        <div className="carousel-button-div">
          <CarouselButton />
        </div>
        <div className="career-hero-content">
          <h1 className="career-heading">Join Our Team</h1>
          <p className="career-subheading">
            Shape the Future of Technology With Us
          </p>
        </div>
      </div>

      <div className="career-content">
        <div className="career-intro">
          <h2>Open Positions</h2>
          <p>
            Discover your next opportunity and be part of something
            extraordinary
          </p>
        </div>

        <div className="jobs-grid">
          {jobListings.map((job) => (
            <JobCard
              key={job.id}
              job={job}
              onClick={() => handleJobClick(job.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CareerPage;
