export const jobListings = [
  {
    id: 1,
    title: "MS Dynamics 365- CRM Functional Consultant",
    location: "Remote",
    department: "Technology",
    experience: "5+ years",
    employmentType: "FULL_TIME",
    description:
      "Join our team as a Microsoft Dynamics 365 CRM Functional Consultant and help drive digital transformation. This role involves gathering requirements, performing business process analysis, and implementing CRM solutions for our clients.",
    requirements: {
      excellent: [
        "Perform requirements gathering, Business Process Analysis, as is and to-be scoping, do Fit/Gap report.",
        "Do all services related to Microsoft Dynamics 365/CRM modules (Sales, Customer Service, Marketing, Field Service and Project automation service) to clients and prospects.",
      ],
      technical: [
        "Proven experience of at least 5+ years as a Dynamics 365 Consultant with expertise in D365 CRM core modules- Sales, customer service and marketing",
        "Strong Technical know-how of Dynamics 365 customisation tools and technologies.",
        "Certifications in Dynamics 365 will be preferred.",
      ],
      additional: [
        "Awareness of latest technologies and trends with skills in documentation skill and flexibility to work onsite or in client time zone.",
      ],
    },
  },
  {
    id: 2,
    title: "Full Stack Developer",
    location: "Hybrid",
    department: "Engineering",
    experience: "3+ years",
    employmentType: "FULL_TIME",
    description:
      "Looking for a passionate Full Stack Developer to join our growing team and build innovative web applications.",
    requirements: {
      excellent: [
        "Strong proficiency in JavaScript and modern frameworks",
        "Experience with React.js and its core principles",
        "Strong understanding of responsive design principles",
      ],
      technical: [
        "3+ years of experience with React.js",
        "Experience with Node.js and Express",
        "Proficiency in HTML5, CSS3, and modern JavaScript",
      ],
      additional: [
        "Experience with agile development methodologies",
        "Strong problem-solving skills and attention to detail",
      ],
    },
  },
  {
    id: 3,
    title: "UI/UX Designer",
    location: "Remote",
    department: "Design",
    experience: "2+ years",
    employmentType: "FULL_TIME",
    description:
      "Join our design team to create beautiful and functional interfaces for our products and clients.",
    requirements: {
      excellent: [
        "Strong portfolio demonstrating UI/UX projects",
        "Experience with design systems and component libraries",
        "Proficiency in modern design tools",
      ],
      technical: [
        "Expert in Figma or similar design tools",
        "Understanding of user-centered design principles",
        "Experience with interaction design",
      ],
      additional: [
        "Knowledge of frontend development is a plus",
        "Experience with design thinking methodology",
      ],
    },
  },
];
