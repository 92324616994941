import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import CarouselButton from "../../components/Button/CarouselButton";
import { jobListings } from "./data";
import "./style.css";

const JobDetailsPage = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();

  const job = jobListings.find((job) => job.id === parseInt(jobId));

  const currentDate = new Date().toISOString();
  const validThrough = new Date();
  validThrough.setDate(validThrough.getDate() + 30);

  const jobSchema = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: job?.title || "",
    description: job?.description || "",
    hiringOrganization: {
      "@type": "Organization",
      name: "Behelam",
    },
    datePosted: currentDate,
    validThrough: validThrough.toISOString(),
    applicantLocationRequirements: {
      "@type": "Country",
      name: "Worldwide",
    },
    jobLocationType: job?.location === "Remote" ? "TELECOMMUTE" : "HYBRID",
    employmentType: job?.employmentType || "FULL_TIME",
    skills: job?.requirements?.technical.join(", "),
    qualifications: job?.requirements?.excellent.join(", "),
  };

  if (!job) {
    return (
      <div className="career-container">
        <div className="career-hero small">
          <div className="carousel-button-div">
            <CarouselButton />
          </div>
          <div className="career-hero-content">
            <h1 className="career-heading">Position Not Found</h1>
            <button
              className="back-button mt-4"
              onClick={() => navigate("/career")}
            >
              Back to Jobs
            </button>
          </div>
        </div>
      </div>
    );
  }

  const handleApply = () => {
    // Implement your application logic here
    alert("Application form would open here");
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(jobSchema)}</script>
      </Helmet>

      <div className="career-container">
        <div className=".about-image small">
          <div className="carousel-button-div">
            <CarouselButton />
          </div>
          <div className="career-hero-content">
            <h1 className="career-heading">{job.title}</h1>
            <p className="career-subheading">
              <span>{job.location}</span> • <span>{job.department}</span> •{" "}
              <span>{job.experience}</span>
            </p>
          </div>
        </div>

        <div className="job-details-content">
          <div className="job-details-card">
            <div className="job-overview">
              <h2>Position Overview</h2>
              <p>{job.description}</p>
            </div>

            <div className="requirements-section">
              <div className="requirement-block">
                <h3>Key Excellence Areas</h3>
                <ul className="requirement-list">
                  {job.requirements.excellent.map((req, index) => (
                    <li key={`excellent-${index}`} className="requirement-item">
                      {req}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="requirement-block">
                <h3>Technical Requirements</h3>
                <ul className="requirement-list">
                  {job.requirements.technical.map((req, index) => (
                    <li key={`technical-${index}`} className="requirement-item">
                      {req}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="requirement-block">
                <h3>Additional Skills</h3>
                <ul className="requirement-list">
                  {job.requirements.additional.map((req, index) => (
                    <li
                      key={`additional-${index}`}
                      className="requirement-item"
                    >
                      {req}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="apply-section">
              <button className="apply-button" onClick={handleApply}>
                Apply Now
              </button>
              <button
                className="back-button"
                onClick={() => navigate("/career")}
              >
                Back to Jobs
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetailsPage;
